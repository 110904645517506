<template>
  <div class="columns is-marginless is-multiline">
    <div class="column is-12">
      <card>
        <template slot="header">
          <p class="card-header-title">Logs</p>
        </template>

        <template slot="body">
          <logs-table :site-id="siteId" />
        </template>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "logs-table": () => import("@shared/logs/_logsTable")
  },
  data() {
    return {
      siteId: this.$route.params.siteId
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (
        vm.$store.getters["sites/site/userRole"](to.params.siteId) ===
          "delegate" &&
        !vm.$store.getters["user/isAdmin"]()
      ) {
        next({ path: `/sites/${to.params.siteId}` });
        vm.$toast.open({
          message: "You don't have permission to access this content",
          type: "is-danger",
          position: "is-bottom",
          queue: false
        });
      }
    });
  }
};
</script>
